<template>
	<div class="change-box">
		<div class="content-title">商户信息</div>
		<el-row>
			<el-col :span="6" class="item-title">商户编号</el-col>
			<el-col :span="12" class="item-value">
				{{ statusData.merchantNo }}
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">商户名称</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.merchantName
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">账户类型</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.accountType | settleAccountTypeFM
			}}</el-col>
			<el-col :span="3" class="item-title">开户名</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.accountName
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">结算账号</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.beforeAccountNo
			}}</el-col>
			<el-col :span="3" class="item-title">开户行</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.beforeBankName
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">开户地区</el-col>
			<el-col :span="6" class="item-value"
				>{{ statusData.beforeAccountProvince | provinceCityFM
				}}{{
					statusData.beforeAccountCity
						| provinceCityFM(statusData.beforeAccountProvince)
				}}</el-col
			>
			<el-col :span="3" class="item-title">开户支行</el-col>
			<el-col :span="6" class="item-value">{{
				statusData.beforeBranchName
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">结算人身份证号</el-col>
			<el-col :span="12" class="item-value">
				{{ maskIDNumber(statusData.settleIdCard) }}
			</el-col>
		</el-row>
		<br />
		<div class="content-title">变更申请</div>
		<el-form
			ref="saveDataform"
			:model="statusData"
			:rules="rules"
			label-width="0"
		>
			<div class="images-box">
				<el-form-item prop="accountImg" style="margin:0 auto">
					<div class="images-item">
						<div class="images-uploader">
							<img
								:src="statusData.afterAccountImg"
								style="width:300px"
							/>
						</div>
						<div class="images-title">
							<span>*</span>结算卡照片（带卡号面）
						</div>
						<div class="images-error">
							{{ statusData.checkErrMsg }}
						</div>
					</div>
				</el-form-item>
			</div>
			<el-row>
				<el-col :span="6" class="item-title">开户名</el-col>
				<el-col :span="6" class="item-value">{{
					statusData.accountName
				}}</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">结算人身份证号</el-col>
				<el-col :span="12" class="item-value">{{
					statusData.settleIdCard
				}}</el-col>
			</el-row>

			<el-row>
				<el-col :span="6" class="item-title">结算账号</el-col>
				<el-col :span="12" class="item-value">
					{{ statusData.afterAccountNo }}
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">开户行</el-col>
				<el-col :span="12" class="item-value">
					{{ statusData.afterBankName }}
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">开户地区</el-col>
				<el-col :span="12" class="item-value">
					{{ statusData.afterAccountProvince | provinceCityFM
					}}{{
						statusData.afterAccountCity
							| provinceCityFM(statusData.afterAccountProvince)
					}}
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">开户支行</el-col>
				<el-col :span="12" class="item-value">
					{{ statusData.afterBranchName }}
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>
<script>
import { MerchantApi } from "@/api";
import { mapState } from "vuex";
export default {
	data() {
		var checkAccountImg = (rule, value, callback) => {
			if (!this.statusData.accountImg) {
				return callback(new Error("请上传结算卡照片"));
			} else {
				value = this.statusData.accountImg;
				callback();
			}
		};
		return {
			statusData: {
				merchantNo: "",
				accountProvince: "",
				accountCity: ""
			},
			statusData: { accountProvince: "", accountCity: "" },
			accountProvinceName: "",
			checked: true,
			isAdd: true,
			applyNo: "",
			cityList: "",
			rules: {
				accountProvince: [
					{ required: true, message: "请选择省", trigger: "change" }
				],
				accountImg: [{ validator: checkAccountImg, trigger: "change" }],
				accountCity: [
					{ required: true, message: "请选择市", trigger: "change" }
				],
				accountNo: [
					{
						required: true,
						message: "请输入结算账号",
						trigger: "blur"
					}
				],
				bankName: [
					{ required: true, message: "请输入开户行", trigger: "blur" }
				],
				branchName: [
					{ required: true, message: "请选择支行", trigger: "change" }
				]
			}
		};
	},
	created() {
		this.isAdd = this.$route.query.isAdd ? true : false;
		this.applyNo = this.$route.query.applyNo;
		if (!this.isAdd) {
			MerchantApi.merChangeApply
				.queryChangeApplyDetail(this.applyNo)
				.then(res => {
					if (res.success) {
						this.statusData = res.data.merSettleChangeApplyDetail;
					}
				});
		}
	},
	computed: {
		...mapState("app", ["provinceCityArea"]),
		provinceList() {
			return "" || this.provinceCityArea;
		}
	},
	methods: {
		getAccountImg(fileList, filename) {
			var file = filename.slice(-1);
			this.statusData.accountImg = filename;
		},
		submit() {
			if (!this.statusData.settleIdCard) {
				this.Message.error("请先查询商户编号");
				return;
			}
			this.$refs.saveDataform.validate(valid => {
				if (valid) {
					MerchantApi.merSettleChangeApplyDetail
						.merSettleChangeApply(
							this.statusData.merchantNo,
							this.statusData
						)
						.then(res => {
							if (res.success) {
								this.Message.success("申请成功");
								this.$router.push({
									name: "merchantChangeApply"
								});
							}
						});
				} else {
					return false;
				}
			});
		},
		async getStastus() {
			if (!this.statusData.merchantNo) {
				this.Message.error("请输入商户编号");
				return;
			}
			let result = await MerchantApi.merSettleChangeApplyDetail.queryMerSettleChangeApply(
				this.statusData.merchantNo
			);
			result.success
				? (this.statusData = result.data)
				: this.Message.error(result.message);
		},
		provinceChange(val) {
			this.statusData.accountCity = "";
			this.accountProvinceName = val.name;
			this.statusData.accountProvince = val.code;
			this.cityList = val.children;
		},
		// 身份证脱敏
		maskIDNumber(IDCode) {
			if (typeof IDCode === "string") {
				return IDCode.replace(/^(\d{6})\d+(\d{4})$/, "$1********$2");
			} else {
				return " ";
			}
		}
	}
};
</script>
<style lang="scss">
.images-error {
	position: absolute;
	left: 50%;
	top: 40px;
	margin-left: 180px;
	color: #ff0000;
	width: 200px;
}
</style>
